<template>
  <div class="py-3 rounded-lg font-medium px-10" :class="tagClass()">
    {{ tag }}
  </div>
</template>
<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
  tag: {
    type: String,
    required: true,
  },
  isBlocked: {
    type: Boolean,
  },
});

const { tag, isBlocked } = toRefs(props);

/**
 * ---------------------------------------------------------------------
 * declaring the tag classes according to the tag coming from the parent
 * ---------------------------------------------------------------------
 */

const tagClass = () => {
  switch (tag.value) {
    case "Submitted":
      return "bg-green-400 bg-opacity-20 text-green-600 ";
    case "Pending":
      if (isBlocked.value) {
        return "disable-tag text-gray-700";
      } else {
        return "bg-blue-300 bg-opacity-20 text-blue-600";
      }
    case "Redo Requested":
    case "Extension Requested":
      return "bg-blue-300 bg-opacity-20 text-blue-600";
    case "Overdue":
      return "bg-red-300 bg-opacity-20 text-red-600 ";
    default:
      return "bg-gray-300 bg-opacity-20 text-gray-600";
  }
};
</script>

<style scoped>
.disable-tag {
  background: rgba(114, 114, 114, 0.336) !important;
}
</style>
