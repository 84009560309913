<template>
  <div
    class="grid gap-y-16 gap-x-12 sm:grid-col-1 lg:grid-cols-2 xl:grid-cols-3"
  >
    <div
      v-for="(card, idx) in cards"
      :key="idx"
      class="bg-white rounded-sm border border-gray-200 relative"
      @click="activeShift(idx, currentShift[idx])"
    >
      <div
        class="flex justify-between border-2 border-gray-800 py-8 pl-8 pr-4"
        :class="
          currentShift[idx] === 1 || shiftActive[idx] === 1
            ? 'border-teal ' + selectedShiftStyle[idx]
            : currentShift[idx] === -1 || shiftActive[idx] == -1
            ? 'border-red ' + selectedShiftStyle[idx]
            : ''
        "
      >
        <div class="font-bold text-xl">
          {{ card.title }}
        </div>
        <div>
          <i
            v-if="
              shiftSlots[idx]?.status === 1 || shiftSlots[idx]?.status === 4
            "
            class="material-icons"
            :class="
              selectedShiftStyle[idx] ? selectedShiftStyle[idx] : 'text-teal'
            "
            >check_circle_outlined</i
          >
          <i
            v-if="currentShift[idx] === -1"
            class="material-icons"
            :class="
              selectedShiftStyle[idx] ? selectedShiftStyle[idx] : 'text-red'
            "
            >info_outlined</i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, defineEmits, computed, watch } from "vue";

/**-------------------------------------------------------------------------*/

const shiftActive = ref([]);
const currentShift = ref([]);
const activeShiftId = ref();
const selectedShiftStyle = ref([]);
const props = defineProps({
  cards: {
    type: Array,
    required: true,
  },
  shiftStatus: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(["active-shift"]);

/**
 * ---------------------------------
 * Making the props reactive
 * ---------------------------------
 */

const { cards, shiftStatus } = toRefs(props);

/**
 * ---------------------------------------------------------------
 * maping the shift to select active shift according to local time
 * ---------------------------------------------------------------
 */

const shiftSlots = computed(() => {
  return shiftStatus.value.map((shift) => {
    return {
      startTime: shift.start_time,
      endTime: shift.end_time,
      status: shift.status,
      id: shift.shift_id,
    };
  });
});

const updateCurrentShift = () => {
  const currentTime = new Date().toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });
  const shiftSlotsArray = shiftSlots.value;
  const isActive = [];
  for (const shift of shiftSlotsArray) {
    const startTime = parseInt(shift.startTime);
    const endTime = parseInt(shift.endTime);

    if (
      startTime <= parseInt(currentTime) &&
      (parseInt(currentTime) < endTime || endTime === 0) &&
      (shift.status === 0 || shift.status === 1)
    ) {
      isActive.push(1);
      activeShiftId.value = shift.id;
      emit("active-shift", {
        id: shift.id - 1,
        shift: cards.value[shift.id - 1],
      });
    } else {
      if (shift.status === 2 && parseInt(currentTime) >= endTime) {
        isActive.push(-1);
      } else {
        isActive.push(0);
      }
    }
  }

  currentShift.value = isActive;
};

/**
 * --------------------------------------------------------
 * Use watcher to call on every time the dependency changes
 * --------------------------------------------------------
 */

watch(
  shiftStatus,
  () => {
    updateCurrentShift();
  },
  { deep: true }
);

/**
 * ----------------------------------------------
 * emiting active shift on the click on the shift
 * ----------------------------------------------
 */

const activeShift = (id, isActive) => {
  shiftActive.value = [];
  selectedShiftStyle.value = [];
  shiftActive.value[id] = 1;
  if (isActive >= 0) selectedShiftStyle.value[id] = "bg-teal text-white";
  else {
    shiftActive.value[id] = -1;
    selectedShiftStyle.value[id] = "bg-red text-white";
  }
  const activeShift = { id: id, shift: cards.value[id] };
  emit("active-shift", activeShift);
};
</script>
