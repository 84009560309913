<!-- @format -->

<template>
  <ModelDayMATiles
    :cards="cards"
    @active-shift="getActiveShiftSlot"
    :shiftStatus="shiftStatuses"
    :key="cards"
  />
  <ModelDayActivitiesList
    :isLoading="loading"
    :shiftStatus="activityStatus"
    :shiftActivities="shiftActivities"
    :submittedInstanceId="submittedActivities?.id"
    :submittedActivities="submittedActivities?.submissions"
    :requests="submittedActivities?.instance_requests"
    :activeShift="activeShift"
    :key="submittedActivities"
  />
</template>

<script setup>
import { ref, watchEffect, computed, onMounted } from "vue";
import { useStore } from "vuex";
import ModelDayMATiles from "@/components/newUi/ModelDay/ModelDayMATiles.vue";
import ModelDayActivitiesList from "@/components/newUi/ModelDay/ModelDayActivitiesList.vue";

/**------------------------------------------------------- */

const activeShift = ref({ id: 1, shift: "Morning 8:00am" });
const loading = ref(false);
const store = useStore();
const cards = ref([
  {
    title: "Morning 8:00 am",
  },
  {
    title: "Mid-Day 11:00 am",
  },
  {
    title: "Evening 4:00 pm",
  },
]);

/**
 * ---------------------------------------------------------------
 * setting the shift according to the event emitted from the child
 * ---------------------------------------------------------------
 */

function getActiveShiftSlot(activeShiftSlot) {
  activeShift.value.id = activeShiftSlot.id + 1;
  activeShift.value.shift = activeShiftSlot.shift;
}

/**
 * ------------------
 * Getters
 * ------------------
 */

const shiftActivities = computed(() => {
  return store.getters["maModelDay/getShiftActivities"];
});

const submittedActivities = computed(() => {
  return store.getters["maModelDay/getInstance"];
});

const activityStatus = computed(() => {
  if (shiftActivities.value.length > 0) {
    return shiftActivities.value[0].status;
  } else {
    return submittedActivities.value.status;
  }
});

const shiftStatuses = computed(() => {
  return store.getters["maModelDay/getAllShiftStatus"];
});

/**
 * ------------------
 * Calls to action
 * ------------------
 */

const fetchStatuses = async () => {
  await store.dispatch("maModelDay/fetchStatusesOfAllShifts", {
    practiceId: localStorage.getItem("practice"),
  });
};

/** Watcheffect cuz if any depencency changes it should run again */

watchEffect(async () => {
  if (shiftActivities.value.length > 0) {
    loading.value = true;
    await store
      .dispatch("maModelDay/fetchSingleInstance", {
        shift: activeShift.value.id,
        instance: shiftActivities.value[0]?.id,
      })
      .then(() => {
        loading.value = false;
      });
  }
});

watchEffect(async () => {
  loading.value = true;
  await store
    .dispatch("maModelDay/fetchShiftActivities", {
      shift: activeShift.value.id,
      practiceId: localStorage.getItem("practice"),
      region: localStorage.getItem("region"),
    })
    .then(() => {
      loading.value = false;
    });
});

onMounted(fetchStatuses);
</script>
