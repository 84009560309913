<!-- @format -->

<template>
  <div class="relative overflow-x-auto shadow-xl rounded-xl bg-white pb-6">
    <div class="px-6 pt-6 pb-4 flex justify-between">
      <div class="text-xl font-medium mt-4">
        {{ activeShiftName }}
      </div>
      <div
        v-if="isLoading"
        class="skeleton-loader w-1/12 h-11 bg-gray-300 animate-pulse rounded"
      ></div>
      <Tags v-else :tag="status" :isBlocked="blocked" />
    </div>
    <div class="px-6"><div class="border border-gray-100"></div></div>
    <div class="flex flex-col px-6 mt-6">
      <div v-if="isLoading">
        <div v-for="(loader, key) in 8" :key="key">
          <div
            class="skeleton-loader w-1/2 py-4 mb-4 bg-gray-300 animate-pulse rounded"
          ></div>
          <div class="flex gap-x-4 py-6">
            <div
              class="skeleton-loader w-1/2 py-6 mb-4 bg-gray-300 animate-pulse rounded"
            ></div>
            <div
              class="skeleton-loader w-1/2 py-6 mb-4 bg-gray-300 animate-pulse rounded"
            ></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, questionId) in activityQuestions" :key="questionId">
          <div
            :class="
              status === 'Pending' && !blocked
                ? 'arrow-active'
                : 'arrow-inActive'
            "
          ></div>
          <span class="font-extralight text-gray-600"
            >{{
              item?.activity?.charAt(0)?.toUpperCase() +
              item?.activity?.slice(1)
            }}
          </span>
          <div class="mb-7 mt-4">
            <div v-if="item?.type === 'string'" class="grid grid-cols-2 gap-4">
              <div
                v-for="(option, optionIndex) in options"
                :key="optionIndex"
                @click="activeOption(questionId, optionIndex)"
                :class="blocked ? 'cursor-not-allowed' : ''"
              >
                <button
                  class="w-full border border-gray-200 rounded p-4 text-left"
                  :class="{
                    [`${fieldColor()} text-white`]: isActive(
                      questionId,
                      optionIndex
                    ),
                  }"
                  :disabled="blocked"
                >
                  {{ option }}
                </button>
              </div>
            </div>
            <div v-else-if="item?.type === 'date'">
              <DatePicker
                :selectedDate="taskDate[questionId]"
                @selected-date="(event) => (taskDate[questionId] = event)"
                @cleared-date="(event) => (taskDate[questionId] = event)"
                :isDisabled="blocked ? true : false"
                :needMaxDate="true"
                :maxDate="date"
                :needMinDate="true"
                :minDate="minDate"
                placeholder="Date"
                class="w-full border border-gray-200 rounded p-4"
              />
            </div>
            <div v-else class="grid grid-cols-1 border border-gray-200 rounded">
              <input
                v-model="numberInputs[questionId]"
                type="number"
                class="rounded p-4"
                placeholder="Type here*"
                :class="blocked ? 'cursor-not-allowed' : ''"
                :disabled="blocked"
                @wheel.prevent=""
                step="1"
                oninput="validity.valid"
                min="0"
                max="9999"
                required
              />
            </div>
            <small
              v-if="!isValidNumber(numberInputs[questionId])"
              class="text-red-500"
              >Decimal Values Cannot be added</small
            >
            <Transition duration="500">
              <div v-show="selectedOption[questionId]">
                <input
                  class="border border-gray-200 w-full rounded p-4 mt-4 transition duration-150 ease-in"
                  type="text"
                  placeholder="Reason here*"
                  v-model="reason[questionId]"
                  :class="blocked ? 'cursor-not-allowed' : ''"
                  :disabled="blocked"
                  maxlength="250"
                  required
                />
              </div>
            </Transition>
            <small
              v-if="
                isEmpty[questionId] &&
                (item?.type === 'string' || item?.type === 'date')
              "
              class="text-red-500"
              >Please fill the field with the relevent response</small
            >
            <small
              v-if="
                (isEmpty[questionId] &&
                  item?.type === 'numeric' &&
                  isValidNumber(numberInputs[questionId])) ||
                numberInputs[questionId] > 9999
              "
              class="text-red-500"
              >Please fill the field with the value between 0 - 9999 without
              decimal places</small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        class="bg-teal py-3 rounded-md mt-2 mx-6 font-light text-sm"
        :class="buttonText.style"
        @click="SubmitForm"
        :disabled="blockButton"
      >
        {{ buttonText.text }}
      </button>
    </div>
    <div class="flex justify-end mr-6 mt-2" v-if="isOverdueShift">
      <small class="text-red"
        >The shift time is up! Kindly ask for extension</small
      >
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import Tags from "@/components/newUi/atoms/Tags.vue";
import Swal from "sweetalert2";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";

/**---------------------------------------------------- */

const store = useStore();
const options = ref(["Yes", "No"]);
const activeItems = ref([]);
const selectedOption = ref([]);
const numberInputs = ref({});
const reason = ref([]);
const isEmpty = ref([]);
const isOverdueShift = ref(false);
const taskDate = ref([]);
const minDate = ref(new Date(new Date().setDate(new Date().getDate() - 3)));

/**
 * ------------------
 * Define the props
 * ------------------
 */

const props = defineProps({
  activeShift: {
    type: Object,
    required: true,
  },
  shiftStatus: {
    type: Number,
    required: true,
  },

  shiftActivities: {
    type: Array,
  },
  submittedActivities: {
    type: Array,
  },
  submittedInstanceId: {
    type: Number,
  },
  requests: {
    type: Array,
  },
  isLoading: {
    type: Boolean,
  },
});

/**
 * -------------------------------------------
 * Destructured the props and made it reactive
 * -------------------------------------------
 */

const {
  shiftActivities,
  activeShift,
  submittedActivities,
  shiftStatus,
  submittedInstanceId,
  requests,
  isLoading,
} = toRefs(props);
const localStatus = ref(shiftStatus.value);

/**
 * -----------------
 * Toast declaration
 * -----------------
 */

const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  iconColor: "white",
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
});

/**
 * --------------------------------------------
 * Check if the number is a whole number or not
 * --------------------------------------------
 */

function isDecimal(num) {
  return num % 1;
}

const isValidNumber = (number) => {
  return !isDecimal(number);
};

/**
 * --------------------------------------------------
 * Setting the Active shift title according to the id
 * --------------------------------------------------
 */

const activeShiftName = computed(() => {
  switch (activeShift.value.id) {
    case 1:
      return "Morning Task 8:00 am";
    case 2:
      return "Mid-Day Task 11:00 am";
    case 3:
      return "Evening Task 4:00 pm";
    default:
      return "";
  }
});

/**
 * --------------------------------------------------------------------------------------
 * setting the status and button style & text according to the status coming from backend
 * --------------------------------------------------------------------------------------
 */

const status = computed(() => {
  switch (
    isOverdueShift.value ? localStatus.value + 1 : shiftStatus.value + 1
  ) {
    case 1:
      return "Pending";
    case 2:
      return "Submitted";
    case 3:
      return "Overdue";
    case 4:
      return "Expired";
    case 5:
      return "Redo Requested";
    case 6:
      return "Extension Requested";
    default:
      return "";
  }
});

const buttonText = computed(() => {
  switch (status.value) {
    case "Pending":
      if (blocked.value) {
        return { text: "Submit", style: "px-10 text-white disable-button" };
      } else {
        return { text: "Submit", style: " px-10 text-white" };
      }
    case "Submitted":
      if (
        isRedoRequested.value?.includes(true) ||
        isExtensionRequested.value?.includes(true)
      )
        return {
          text: "Redo",
          style: "px-10 text-white cursor-not-allowed bg-opacity-20",
        };
      return { text: "Redo", style: "px-10 text-white " };
    case "Redo Requested":
    case "Extension Requested":
      return {
        text: "Request Sent!",
        style: "px-6 bg-opacity-20 text-teal cursor-not-allowed",
      };
    case "Overdue":
      return { text: "Extension", style: "px-10 text-white" };
    default:
      return {
        text: "Expired",
        style: "px-10 text-gray-900 bg-gray-300 bg-opacity-20",
      };
  }
});

const blockButton = computed(() =>
  buttonText.value.text.includes("Request Sent!")
    ? true
    : false ||
      (status.value === "Submitted" &&
        (isRedoRequested.value?.includes(true) ||
          isExtensionRequested.value?.includes(true)))
    ? true
    : false
);
/**
 * -------------------------------------------------------------------------------------
 * blocking the field if the status is other than pending and if the shift is not active
 * -------------------------------------------------------------------------------------
 */
const blocked = computed(() =>
  status.value === "Pending" &&
  (isActiveShift(activeShift.value.id) ||
    isRedoRequested.value?.includes(true) ||
    isExtensionRequested.value?.includes(true))
    ? false
    : true
);

const isActiveShift = (shiftId) => {
  const currentTime = new Date().toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });
  const localTime = parseInt(currentTime);
  switch (shiftId) {
    case 1:
      if (localTime >= 8 && localTime < 11) {
        return true;
      }
      break;
    case 2:
      if (localTime >= 11 && localTime < 16) {
        return true;
      }
      break;
    case 3:
      if (localTime >= 16 && localTime < 20) {
        return true;
      }
      break;
  }
};

/**
 * ----------------------------------------------------------------------
 * mapping the activites for submission according to the structure needed
 * ----------------------------------------------------------------------
 */

const mappedActivities = computed(() => {
  return shiftActivities?.value.map((shift) => {
    const shiftId = shift.id;
    const instanceRequests = shift.instance_requests;
    const activities = shift.shift?.activities.map((activity) => {
      const {
        activity: activityName,
        id: activityId,
        type: activityType,
      } = activity;
      const threshold = activity.thresholds[0]?.threshold;
      return {
        activity: activityName,
        id: activityId,
        type: activityType,
        threshold,
      };
    });
    return { activities, shiftId: shiftId, instanceRequests: instanceRequests };
  });
});

/**
 * ----------------------------------------------------------------
 * mapping the submitted activity according to the structure needed
 * ----------------------------------------------------------------
 */

const submittedQuestions = computed(() => {
  if (submittedActivities.value) {
    submittedActivities.value.forEach(() => {
      activeItems.value.push(ref(null));
      selectedOption.value.push(false);
    });
    submittedActivities.value.map((activity, index) => {
      const optionId = activity.answer === "no" ? 1 : 0;
      activeItems.value[index].value = optionId;
      if (options.value[optionId] === "No") {
        selectedOption.value[index] = true;
        reason.value[index] = activity.reason;
      } else {
        selectedOption.value[index] = false;
      }
      if (activity.activity.type === "numeric") {
        numberInputs.value[index] = parseInt(activity.answer);
      }
      if (activity.activity.type === "date") {
        taskDate.value[index] = new Date(activity.answer);
      }
    });
    return submittedActivities.value.map((activity) => {
      return {
        submissionId: activity.id,
        activity: activity.activity.activity,
        id: activity.activity.id,
        type: activity.activity.type,
      };
    });
  } else {
    return [];
  }
});

const fieldColor = () => {
  if (shiftStatus.value === 0) {
    return "bg-teal";
  } else {
    return "disable-button cursor-not-allowed";
  }
};

const activeOption = (questionIndex, optionIndex) => {
  activityQuestions?.value.forEach(() => {
    activeItems.value.push(ref(null));
    selectedOption.value.push(false);
  });
  activeItems.value[questionIndex].value = optionIndex;
  if (options.value[optionIndex] === "No") {
    selectedOption.value[questionIndex] = true;
  } else {
    selectedOption.value[questionIndex] = false;
  }
};

const isActive = (questionIndex, optionIndex) => {
  const activeItem = activeItems.value[questionIndex];
  return activeItem && activeItem.value === optionIndex;
};

/**
 * -----------------------------------
 * Activity Questions for the template
 * -----------------------------------
 */

const activityQuestions = computed(() => {
  if (submittedQuestions.value?.length > 0) {
    return submittedQuestions.value;
  } else {
    return mappedActivities.value[0]?.activities;
  }
});

/**
 * --------------------------------
 * setting for the instance request
 * --------------------------------
 */

const instanceRequests = computed(() => {
  return mappedActivities.value[0]?.instanceRequests.map((requests) => {
    return { id: requests.id, type: requests.type };
  });
});

const isRedoRequested = computed(() => {
  if (instanceRequests.value?.length > 0) {
    return instanceRequests.value?.map((requests) => {
      return requests.type === "redo";
    });
  } else {
    return requests.value?.map((requests) => {
      return requests.type === "redo";
    });
  }
});

const isExtensionRequested = computed(() => {
  if (instanceRequests.value?.length > 0) {
    return instanceRequests.value?.map((requests) => {
      return requests.type === "extension";
    });
  } else {
    return requests.value?.map((requests) => {
      return requests.type === "extension";
    });
  }
});

/**
 * -------------------------------------------------------
 * mapping the iinstance id to post the activity responses
 * -------------------------------------------------------
 */

const instanceId = computed(() => {
  return mappedActivities.value[0]?.shiftId;
});

/**
 * -------------------------
 * submitting the activities
 * -------------------------
 */

const SubmitForm = async () => {
  const practiceId = localStorage.getItem("practice");
  if (status.value === "Pending") {
    const formData = [];
    let formValid = true;
    isEmpty.value = Array(activityQuestions.value.length).fill(false);
    activityQuestions.value.forEach((question, index) => {
      const selectedOptionIndex = activeItems?.value[index]?.value;
      const activity = activityQuestions?.value[index];
      const id = activity?.id;
      const threshold = question?.threshold;
      const selectedOptionText = options?.value[selectedOptionIndex];
      const type = activity?.type;
      if (
        numberInputs.value[index] >= 0 &&
        numberInputs.value[index] <= 9999 &&
        !isDecimal(numberInputs.value[index])
      ) {
        if (isRedoRequested.value?.includes(true)) {
          if (
            numberInputs.value[index] >= 0 &&
            numberInputs.value[index] <= 9999 &&
            numberInputs.value[index] !== "" &&
            !isDecimal(numberInputs.value[index])
          ) {
            formData.push({
              id: activity.submissionId,
              activity: id,
              answer: numberInputs.value[index],
              threshold,
            });
          } else {
            isEmpty.value[index] = true;
            formValid = false;
            return;
          }
        } else {
          if (numberInputs.value[index] === "") {
            isEmpty.value[index] = true;
            formValid = false;
            return;
          }
          formData.push({
            activity: id,
            answer: numberInputs.value[index],
            threshold,
          });
        }
      } else if (
        type === "numeric" &&
        numberInputs.value[index] === undefined
      ) {
        isEmpty.value[index] = true;
        formValid = false;
      } else if (type === "date") {
        if (!taskDate.value[index]) {
          isEmpty.value[index] = true;
          formValid = false;
        } else {
          if (isRedoRequested.value?.includes(true)) {
            formData.push({
              id: activity.submissionId,
              activity: id,
              answer: taskDate.value[index],
            });
          } else {
            formData.push({
              activity: id,
              answer: taskDate.value[index],
            });
          }
        }
      } else {
        if (!selectedOptionText) {
          isEmpty.value[index] = true;
          formValid = false;
        } else if (selectedOptionText === "No") {
          const reasonField = reason.value[index];
          if (!reasonField) {
            isEmpty.value[index] = true;
            formValid = false;
          } else {
            if (isRedoRequested.value?.includes(true)) {
              formData.push({
                id: activity.submissionId,
                activity: id,
                answer: selectedOptionText.toLowerCase(),
                reason: reasonField,
              });
            } else {
              formData.push({
                activity: id,
                answer: selectedOptionText.toLowerCase(),
                reason: reasonField,
              });
            }
          }
        } else {
          if (isRedoRequested.value?.includes(true)) {
            if (
              numberInputs.value[index] < 0 ||
              numberInputs.value[index] > 9999 ||
              isDecimal(numberInputs.value[index])
            ) {
              isEmpty.value[index] = true;
              formValid = false;
              return;
            }

            formData.push({
              id: activity.submissionId,
              activity: id,
              answer: selectedOptionText.toLowerCase(),
            });
          } else {
            formData.push({
              activity: id,
              answer: selectedOptionText.toLowerCase(),
            });
          }
          isEmpty.value[index] = false;
        }
      }
    });

    const payload = {
      instance: instanceId.value,
      practice: parseInt(practiceId),
      activities: formData,
      shiftId: activeShift.value.id - 1,
    };
    if (!formValid) {
      return;
    }
    if (isRedoRequested.value?.includes(true)) {
      await store
        .dispatch("maModelDay/updateInstanceSubmission", payload)
        .then(() => {
          Toast.fire({
            icon: "success",
            background: "#00A499",
            color: "#FFFFFF",
            title: "Instance has been updated",
            timerProgressBar: false,
            width: "30em",
          });
        });
    } else {
      if (isActiveShift(activeShift.value.id) || isExtensionRequested.value) {
        await store
          .dispatch("maModelDay/submitShiftActivity", payload)
          .then(() => {
            Toast.fire({
              icon: "success",
              background: "#00A499",
              color: "#FFFFFF",
              title: "Shift has been submitted successfully",
              timerProgressBar: false,
              width: "30em",
            });
          });
      } else {
        localStatus.value = 2;
        isOverdueShift.value = true;
        await store.dispatch("maModelDay/fetchStatusesOfAllShifts", {
          practiceId: localStorage.getItem("practice"),
        });
      }
    }
  } else {
    let payload = {};
    if (
      isRedoRequested.value?.includes(true) ||
      isExtensionRequested.value?.includes(true)
    ) {
      await Toast.fire({
        icon: "info",
        background: "#F9CB40",
        color: "#FFFFFF",
        title: "Redo or Extension can only be requested once",
        timerProgressBar: false,
        width: "34em",
      });
      return;
    }
    if (status.value === "Submitted") {
      payload = {
        instance: submittedInstanceId.value,
        practice: practiceId,
        request_type: "redo",
        status: 4,
      };
    } else {
      payload = {
        instance: submittedInstanceId.value,
        practice: practiceId,
        request_type: "extension",
        status: 5,
      };
    }
    await store
      .dispatch("maModelDay/requestRedoOrExtension", payload)
      .then(() => {
        Toast.fire({
          icon: "success",
          background: "#00A499",
          color: "#FFFFFF",
          title: "Request has been sent",
          timerProgressBar: false,
          width: "30em",
        });
      });
  }
};
</script>
<style scoped>
.disable-button {
  background: rgba(114, 114, 114, 1) !important;
}

.skeleton-loader {
  background-color: #f0f0f0;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-loader-placeholder {
  margin-bottom: 1rem;
}

.arrow-active {
  width: 0;
  height: 0;
  padding-bottom: 3px;
  margin-right: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--Submit, rgba(0, 164, 153, 1));
  transform: rotate(90deg);
  display: inline-block;
}

.arrow-inActive {
  width: 0;
  height: 0;
  padding-bottom: 3px;
  margin-right: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(114, 114, 114, 1);
  transform: rotate(90deg);
  display: inline-block;
}
</style>
